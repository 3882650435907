import * as React from 'react';
import { isEqual } from 'lodash';
import * as Sentry from '@sentry/react';
import styled from '@emotion/styled';
import { lineHasChild, sortLinesByIndex } from '../../utils';
import TicketHeader from '../../TicketHeader';
import Formula from '../Formula';
import TicketItem from '../TicketItem';
import TicketPopOver from '../../TicketPopOver';
import { Routes } from '../../../../routes.types';
import DisplayedTicketActions from '../../DisplayedTicketActions';
import { PopOverType } from '../../../../redux/tickets/user.actions';
import { KdsTicket, TicketStatus } from '../../../../redux/tickets/types/ticket';
import { TicketBody, TicketContainer } from '../style';

const Label = styled.p`
    font-size: ${({ theme }) => theme.size.textSmall};
    font-family: ${({ theme }) => theme.font.familySemiBold};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.bloodyMary};
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

type Props = {
    dispatchTicketClicked: (orderId: string) => void;
    dispatchLineClicked: (orderId: string, linePath: string) => void;
    dispatchUndisplayTicketClicked: (orderId: string) => void;
    dispatchStatusChange: (orderIds: string[], type: PopOverType, route: Routes) => void;
    isCompactMode: boolean;
    ticket: KdsTicket;
    route: Routes;
};

const SimpleTicket: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    dispatchTicketClicked,
    dispatchLineClicked,
    dispatchUndisplayTicketClicked,
    dispatchStatusChange,
    isCompactMode,
    ticket,
    route,
}) => {
    const { brandId, restaurantId, kdsLines, orderId, ticketNumber, status, comment } = ticket;
    if (!Object.values(ticket.kdsLines).length) {
        Sentry.captureEvent({
            message: 'Ticket arrived without lines',
            extra: {
                brandId,
                orderId,
                restaurantId,
                ticketNumber,
            },
        });

        return null;
    }

    const lines = Object.values(kdsLines);
    const sortedLines = sortLinesByIndex(lines);

    return (
        <TicketContainer
            key={`${ticket.orderId}-${ticket.status}-${isCompactMode}`}
            data-testid="simple-ticket"
            className="ticket-container"
            compactMode={isCompactMode}
        >
            <TicketHeader {...ticket} dispatchTicketClicked={dispatchTicketClicked} />

            {comment !== null && <Label data-testid="ticket-comment">" {comment} "</Label>}

            {status === TicketStatus.Displaying ? (
                <DisplayedTicketActions
                    dispatchUndisplayTicket={() => dispatchUndisplayTicketClicked(orderId)}
                    dispatchTicketClicked={dispatchTicketClicked}
                    ticket={ticket}
                />
            ) : (
                <TicketBody data-testid="ticket-body" compactMode={isCompactMode}>
                    {sortedLines.map((line) =>
                        lineHasChild(line) ? (
                            <Formula
                                {...line}
                                key={line.uuid}
                                orderId={orderId}
                                dispatchLineClicked={dispatchLineClicked}
                                depth={0}
                                isCompactMode={isCompactMode}
                            />
                        ) : (
                            <TicketItem
                                {...line}
                                key={line.uuid}
                                orderId={orderId}
                                dispatchLineClicked={dispatchLineClicked}
                                depth={0}
                                isCompactMode={isCompactMode}
                            />
                        ),
                    )}
                </TicketBody>
            )}
            {(status === TicketStatus.Modified || status === TicketStatus.ToCancel) && (
                <TicketPopOver
                    orderIds={[orderId]}
                    type={status}
                    route={route}
                    ticketNumbers={[ticketNumber]}
                    onPopOverClick={dispatchStatusChange}
                />
            )}
        </TicketContainer>
    );
};

export default React.memo(SimpleTicket, isEqual);
